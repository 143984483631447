import "styles/pages/page-recreation.scss";

import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import Breadcrumbs from "../components/Breadcrumbs";

const PageRecreation = ({ pageContext }) => {
	const pageRecreation = pageContext.pageContent;
	return (
		<Layout headerColor="#6AD044">
			<Seo
				title={
					pageContext.seo.title
						? pageContext.seo.title
						: pageContext.title
				}
				description={
					pageContext.seo.metaDesc && pageContext.seo.metaDesc
				}
			/>
			<SubpageHeader
				image={
					require("assets/images/recreation-header-bg.jpg").default
				}
			/>
			<Breadcrumbs currentLocationLabel="Rekreacje" />
			<section className="recreation-active">
				<div className="container">
					<h2 className="section-title">
						{pageRecreation.activityTitle}
					</h2>
					<div className="row">
						<div
							className="col-md-8 offset-xl-1"
							dangerouslySetInnerHTML={{
								__html: pageRecreation.activityDesc,
							}}
						></div>
					</div>
				</div>
			</section>
			<section className="recreation-diving">
				<div className="container">
					<h2 className="section-title">
						{pageRecreation.divingTitle}
					</h2>
					<div className="row">
						<div className="col-xl-6 col-md-6 offset-xl-1">
							<div
								className="recreation-diving__text"
								dangerouslySetInnerHTML={{
									__html: pageRecreation.divingDesc,
								}}
							></div>
							<img
								src={pageRecreation.divingImg3?.sourceUrl}
								alt=""
								className="img-fluid recreation-diving__img recreation-diving__img--3"
							/>
						</div>
						<div className="col-lg-5 col-md-6">
							<img
								src={pageRecreation.divingImg1?.sourceUrl}
								alt=""
								className="img-fluid recreation-diving__img recreation-diving__img--1"
							/>
							<img
								src={pageRecreation.divingImg2?.sourceUrl}
								alt=""
								className="img-fluid recreation-diving__img recreation-diving__img--2"
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="recreation-climbing">
				<div className="container">
					<div className="row flex-md-row flex-column-reverse">
						<div className="col-md-6">
							<img
								src={pageRecreation.climbingImg?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
						<div className="col-xl-5 col-md-6 offset-xl-1">
							<h2 className="section-title">
								{pageRecreation.climbingTitle}
							</h2>
							<div
								className="recreation-climbing__text"
								dangerouslySetInnerHTML={{
									__html: pageRecreation.climbingDesc,
								}}
							></div>
						</div>
					</div>
				</div>
			</section>
			<section className="recreation-golf">
				<div className="container">
					<h2 className="section-title">
						{pageRecreation.golfTitle}
					</h2>
					<div className="row">
						<div className="col-xl-5 col-md-6 offset-xl-1">
							<div
								className="recreation-golf__text"
								dangerouslySetInnerHTML={{
									__html: pageRecreation.golfDesc,
								}}
							></div>
						</div>
						<div className="col-xl-5 col-md-6 offset-xl-1">
							<img
								src={pageRecreation.golfImg1?.sourceUrl}
								alt=""
								className="img-fluid recreation-golf__img recreation-golf__img--1"
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-5">
							<img
								src={pageRecreation.golfImg2?.sourceUrl}
								alt=""
								className="img-fluid recreation-golf__img recreation-golf__img--3"
							/>
						</div>
						<div className="col-md-5 offset-md-1">
							<img
								src={pageRecreation.golfImg3?.sourceUrl}
								alt=""
								className="img-fluid recreation-golf__img recreation-golf__img--2"
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="recreation-rafting">
				<div className="container">
					<div className="row flex-md-row flex-column-reverse">
						<div className="col-md-6">
							<img
								src={pageRecreation.raftingImg?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
						<div className="col-xl-5 col-md-6 offset-xl-1">
							<div className="recreation-rafting__text-container">
								<h2 className="section-title">
									{pageRecreation.raftingTitle}
								</h2>
								<div
									className="recreation-rafting__text"
									dangerouslySetInnerHTML={{
										__html: pageRecreation.raftingDesc,
									}}
								></div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="recreation-cave">
				<div className="container">
					<div className="row">
						<div className="col-md-7">
							<div className="recreation-cave__text-container">
								<h2 className="section-title">
									{pageRecreation.exploringTitle}
								</h2>
								<div
									className="recreation-cave__text"
									dangerouslySetInnerHTML={{
										__html: pageRecreation.exploringDesc,
									}}
								></div>
							</div>
						</div>
						<div className="col-md-5">
							<img
								src={pageRecreation.exploringImg?.sourceUrl}
								alt=""
								className="img-fluid recreation-cave__img"
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="recreation-sailing">
				<div className="container">
					<div className="row flex-md-row flex-column-reverse">
						<div className="col-lg-7 col-md-6">
							<img
								src={pageRecreation.sailingImg1?.sourceUrl}
								alt=""
								className="img-fluid recreation-sailing__img recreation-sailing__img--1"
							/>
							<div className="row">
								<div className="col-md-5">
									<img
										src={
											pageRecreation.sailingImg2
												?.sourceUrl
										}
										alt=""
										className="img-fluid recreation-sailing__img recreation-sailing__img--2"
									/>
								</div>
								<div className="col-md-7">
									<img
										src={
											pageRecreation.sailingImg3
												?.sourceUrl
										}
										alt=""
										className="img-fluid recreation-sailing__img recreation-sailing__img--3"
									/>
								</div>
							</div>
						</div>
						<div className="col-lg-5 col-md-6">
							<div className="recreation-sailing__text-container">
								<h2 className="section-title">
									{pageRecreation.sailingTitle}
								</h2>
								<div
									className="recreation-sailing__text"
									dangerouslySetInnerHTML={{
										__html: pageRecreation.sailingDesc,
									}}
								></div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default PageRecreation;
